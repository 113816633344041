import React from "react";
import Web3 from "web3";
import { contractAddress, network } from "./config";
import abi from "./ABI.json";
import CustomButton from "./CustomButton";

class Home extends React.Component {


  async mintJohnny() {
    const web3 = new Web3(window.ethereum);
          try {
            // Request user permission to connect to wallet
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const selectedAccount = accounts[0];
            const network = 1; // Set the expected network ID here
        
            // Check if the network is correct
            const networkId = await web3.eth.net.getId();
            if (networkId !== network) {
              console.error("Not connected to the correct network");
              return;
            }
        
            const contract = new web3.eth.Contract(abi, contractAddress);
            await contract.methods.mint(1).send({ from: selectedAccount, value: web3.utils.toWei('0.05', 'ether'), gas: 250000 });
          } catch (error) {
            console.error(error);
            // Handle errors that may occur when connecting to the wallet or calling the mint function
          }
        }
  

    async mintPickaxe() {
        const web3 = new Web3(window.ethereum);
            try {
              // Request user permission to connect to wallet
              const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
              const selectedAccount = accounts[0];
              const network = 1; // Set the expected network ID here
          
              // Check if the network is correct
              const networkId = await web3.eth.net.getId();
              if (networkId !== network) {
                console.error("Not connected to the correct network");
                return;
              }    

                const contract = new web3.eth.Contract(abi, contractAddress);
                await contract.methods.mint(2).send({ from: selectedAccount, value: web3.utils.toWei('0', 'ether'), gas: 250000 });
            } catch (error) {
                console.error(error);
                // Handle errors that may occur when connecting to the wallet or calling the mint function
        }
    
    }

    async mintWindmill() {
        const web3 = new Web3(window.ethereum);
            try {
              // Request user permission to connect to wallet
              const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
              const selectedAccount = accounts[0];
              const network = 1; // Set the expected network ID here
          
              // Check if the network is correct
              const networkId = await web3.eth.net.getId();
              if (networkId !== network) {
                console.error("Not connected to the correct network");
                return;
              } 

                const contract = new web3.eth.Contract(abi, contractAddress);
                await contract.methods.mint(3).send({ from: selectedAccount, value: web3.utils.toWei('0.03', 'ether'), gas: 250000 });
            } catch (error) {
                console.error(error);
                // Handle errors that may occur when connecting to the wallet or calling the mint function
        }
    
    }

    async mintPepe() {
        const web3 = new Web3(window.ethereum);
            try {
              // Request user permission to connect to wallet
              const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
              const selectedAccount = accounts[0];
              const network = 1; // Set the expected network ID here
          
              // Check if the network is correct
              const networkId = await web3.eth.net.getId();
              if (networkId !== network) {
                console.error("Not connected to the correct network");
                return;
              }

                const contract = new web3.eth.Contract(abi, contractAddress);
                await contract.methods.mint(4).send({ from: selectedAccount, value: web3.utils.toWei('0.25', 'ether'), gas: 250000 });
            } catch (error) {
                console.error(error);
                // Handle errors that may occur when connecting to the wallet or calling the mint function
        }
    
    }

    async mintBTCMinerPack() {
        const web3 = new Web3(window.ethereum);
            try {
                // Request user permission to connect to wallet
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const selectedAccount = accounts[0];
                const network = 1; // Set the expected network ID here
            
                // Check if the network is correct
                const networkId = await web3.eth.net.getId();
                if (networkId !== network) {
                  console.error("Not connected to the correct network");
                  return;
                }

            const contract = new web3.eth.Contract(abi, contractAddress);
            await contract.methods.mintMinerPack([1,2,3,6], [1,1,1,1], 0x00).send({ from: selectedAccount, value: web3.utils.toWei('0.08', 'ether'), gas: 300000 });
        } catch (error) {
            console.error(error);
            // Handle errors that may occur when connecting to the wallet or calling the mint function
        }

    }

    async mintETHMinerPack() {
        const web3 = new Web3(window.ethereum);
          try {
            // Request user permission to connect to wallet
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const selectedAccount = accounts[0];
            const network = 1; // Set the expected network ID here
        
            // Check if the network is correct
            const networkId = await web3.eth.net.getId();
            if (networkId !== network) {
              console.error("Not connected to the correct network");
              return;
            }

            const contract = new web3.eth.Contract(abi, contractAddress);
            await contract.methods.mintMinerPack([1,2,3,7], [1,1,1,1], 0x00).send({ from: selectedAccount, value: web3.utils.toWei('0.08', 'ether'), gas: 300000 });
        } catch (error) {
            console.error(error);
            // Handle errors that may occur when connecting to the wallet or calling the mint function
        }

    }

    async mintSKLMinerPack() {
        const web3 = new Web3(window.ethereum);
            try {
              // Request user permission to connect to wallet
              const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
              const selectedAccount = accounts[0];
              const network = 1; // Set the expected network ID here
          
              // Check if the network is correct
              const networkId = await web3.eth.net.getId();
              if (networkId !== network) {
                console.error("Not connected to the correct network");
                return;
              }
            const contract = new web3.eth.Contract(abi, contractAddress);
            await contract.methods.mintMinerPack([1,2,3,8], [1,1,1,1], 0x00).send({ from: selectedAccount, value: web3.utils.toWei('0.08', 'ether'), gas: 300000 });
        } catch (error) {
            console.error(error);
            // Handle errors that may occur when connecting to the wallet or calling the mint function
        }

    }

    async mintOneEthPack() {
        const web3 = new Web3(window.ethereum);
          try {
            // Request user permission to connect to wallet
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const selectedAccount = accounts[0];
            const network = 1; // Set the expected network ID here
        
            // Check if the network is correct
            const networkId = await web3.eth.net.getId();
            if (networkId !== network) {
              console.error("Not connected to the correct network");
              return;
            }

            const contract = new web3.eth.Contract(abi, contractAddress);
            await contract.methods.mintOneEthPack([1,2,3,5,6,7,8], [1,1,4,1,5,5,5]).send({ from: selectedAccount, value: web3.utils.toWei('1', 'ether'), gas: 900000});
        } catch (error) {
            console.error(error);
            // Handle errors that may occur when connecting to the wallet or calling the mint function
        }

    }

  render() {
    return (
      <div className="u-body u-xl-mode" data-lang="en">
        <header
          className=" u-border-no-bottom u-border-no-left u-border-no-right u-border-no-top u-clearfix u-header u-section-row-container"
          id="sec-f307"
          style={{}}
        >
          <div className="u-section-rows">
            <div
              className="u-border-2 u-border-white u-palette-4-dark-2 u-section-row u-sticky u-sticky-687d u-section-row-1"
              id="sec-bdc5"
            >
              <div className="u-clearfix u-sheet u-sheet-1">
                <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-1">
                  <div className="u-layout">
                    <div className="u-layout-row">
                      <div className="u-container-style u-layout-cell u-size-30 u-layout-cell-1">
                        <div className="u-container-layout u-container-layout-1">
                          <a
                            href="https://www.voxelverse.ca"
                            className="u-border-2 u-border-grey-5 u-btn u-btn-round u-button-style u-custom-color-5 u-hidden-sm u-hidden-xs u-hover-palette-1-light-1 u-radius-50 u-btn-1"
                            target="_blank"
                            rel="noreferrer"
                          >
                            enter beta gameplay
                          </a>
                          <h6 className="u-align-center u-custom-font u-hidden-sm u-hidden-xs u-text u-text-default u-text-palette-5-light-2 u-text-1">
                            play now
                          </h6>
                        </div>
                      </div>
                      <div className="u-container-style u-layout-cell u-size-30 u-layout-cell-2">
                        <div className="u-container-layout u-container-layout-2">
                          <CustomButton />
                          <h6 className="u-align-center u-custom-font u-text u-text-default u-text-palette-3-base u-text-2">
                            connect wallet to shop
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="u-image u-section-row u-shading u-image-1"
              data-image-width="1493"
              data-image-height="1024"
              id="sec-32ba"
            >
              <div className="u-clearfix u-sheet u-sheet-2">
                <img
                  className="u-image u-image-circle u-image-contain u-image-2"
                  src="assets/images/5.png"
                  alt=""
                  data-image-width="3840"
                  data-image-height="2160"
                />
                <h4 className="u-custom-font u-text u-text-default u-text-palette-1-base u-text-3">
                  voxel
                </h4>
                <h4 className="u-custom-font u-text u-text-default u-text-palette-4-base u-text-4">
                  verse
                </h4>
                <h4 className="u-align-center u-custom-font u-text u-text-custom-color-5 u-text-default u-text-5">
                  forge, survive and build your mining empire
                  <span style={{ fontweight: "700" }}></span>.<br />
                </h4>
                <img
                  className="u-image u-image-contain u-image-default u-image-3"
                  src="assets/images/4sourdough.png"
                  alt=""
                  data-image-width="3840"
                  data-image-height="2160"
                />
                <h6 className="u-align-center u-custom-font u-text u-text-default u-text-palette-3-base u-text-6">
                  prospectorsnft
                </h6>
                <h4 className="u-align-center u-custom-font u-text u-text-grey-5 u-text-7">
                  3e interactive
                </h4>
              </div>
            </div>
          </div>
        </header>
        <section className="u-clearfix u-section-1" id="carousel_b9e6">
      <div className="u-align-left u-border-2 u-border-grey-75 u-expanded u-left-0 u-video">
        <div className="embed-responsive embed-responsive-1">
          <iframe styleName="position: absolute;top: 0;left: 0;width: 100%;height: 100%;" className="embed-responsive-item" src="https://www.youtube.com/embed/eKw7Z7S83As?playlist=eKw7Z7S83As&amp;loop=1&amp;mute=1&amp;showinfo=0&amp;controls=0&amp;start=0&amp;autoplay=1" data-autoplay="1" frameborder="0" allowfullscreen=""></iframe>
        </div>
      </div>
    </section>
        <section
          className="u-clearfix u-image u-section-2"
          id="carousel_b2b3"
          data-image-width="1980"
          data-image-height="1200"
        >
          <div className="u-clearfix u-sheet u-sheet-1">
            <div className="u-clearfix u-expanded-width u-gutter-6 u-layout-wrap u-layout-wrap-1">
              <div className="u-layout">
                <div className="u-layout-row">
                  <div
                    className="u-container-style u-image u-image-round u-layout-cell u-radius-12 u-right-cell u-shape-rectangle u-size-30 u-image-1"
                    data-image-width="1536"
                    data-image-height="1536"
                  >
                    <div className="u-border-2 u-border-grey-10 u-container-layout u-container-layout-1"></div>
                  </div>
                  <div className="u-align-left u-container-style u-layout-cell u-left-cell u-radius-12 u-shape-round u-size-30 u-layout-cell-2">
                    <div className="u-border-2 u-border-grey-10 u-container-layout u-container-layout-2">
                      <h6 className="u-align-center u-custom-font u-font-montserrat u-text u-text-body-alt-color u-text-1">
                        limited availability
                        <br />
                        legendary drop
                        <br />
                      </h6>
                      <h4 className="u-align-center u-custom-font u-text u-text-palette-4-base u-text-2">
                        special edition
                      </h4>
                      <h2 className="u-align-center u-custom-font u-text u-text-palette-3-base u-text-3">
                        miner packs
                      </h2>
                      <h3 className="u-align-center u-custom-font u-text u-text-palette-4-base u-text-4">
                        earn additional rewards on top of your
                        <br />
                        in game prospect &amp; skale drops
                      </h3>
                      <h4 className="u-align-center u-custom-font u-text u-text-body-alt-color u-text-5">
                        1000 bitcoin PACKS
                        <br />
                        1000 ethereum PACKS
                        <br />
                        1000 skale PACKS
                        <br />
                        100 bulk miner packs
                      </h4>
                      <h5 className="u-align-center u-custom-font u-text u-text-palette-4-light-1 u-text-6">*<br />
                        EACH MINER IS SUPPORTED BY BITCOIN MINING AND SKL
                        RESERVES
                        *<br />
                        ALL S.E. MINERS FEATURE UPGRADEABLE HASHRATES
                        *<br />
                        S.E. MINERS ARE NOT CRAFTABLE IN GAME
                        *<br />
                        USE RESOURCES AND PROSPECT TO BUILD OTHER MINERS
                      </h5>
                      <h5 className="u-align-center u-custom-font u-text u-text-body-alt-color u-text-7">
                        see all packs below
                        <br />
                        <br />
                        read about the&nbsp;<a href="https://shop.pickaxecrypto.io/mintingprocess.html" class="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1" target="_blank">minting process</a> prior
                        to minting
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="u-clearfix u-expanded-width u-gutter-6 u-layout-wrap u-layout-wrap-2">
              <div className="u-layout">
                <div className="u-layout-col">
                  <div className="u-size-30">
                    <div className="u-layout-row">
                      <div className="u-container-style u-layout-cell u-palette-4-dark-3 u-radius-12 u-shape-round u-size-30 u-layout-cell-3">
                        <div className="u-container-layout u-container-layout-3">
                          <h5 className="u-custom-font u-text u-text-body-alt-color u-text-default u-text-8">
                            special edition bitcoin miner pack
                            <br />
                          </h5>
                          <img
                            className="u-border-2 u-border-grey-75 u-image u-image-circle u-preserve-proportions u-image-2"
                            src="assets/images/SKLMiner.png"
                            alt=""
                            data-image-width="1536"
                            data-image-height="1536"
                          />
                          <span className="u-file-icon u-icon u-icon-circle u-palette-1-base u-text-black u-icon-1">
                            <img
                              src="assets/images/5968260-5c8f14df.png"
                              alt=""
                            />
                          </span>
                          <h6 className="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-custom-font u-text u-text-default u-text-palette-4-light-2 u-text-9">
                            includes
                            <br />
                            -one johnny newcome <br />
                            -one pickaxe
                            <br />
                            -one windmill
                            <br />
                            -one s.e. bitcoin miner
                          </h6>
                          <a
                            onClick={this.mintBTCMinerPack}
                            className="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-border-none u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-4-base u-radius-50 u-btn-2"
                          >
                            mint one
                          </a>
                          <h4 className="u-align-left u-custom-font u-text u-text-default u-text-10">
                            0.08 eth
                          </h4>
                        </div>
                      </div>
                      <div className="u-container-style u-custom-color-8 u-layout-cell u-radius-12 u-shape-round u-size-30 u-layout-cell-4">
                        <div className="u-container-layout u-container-layout-4">
                          <h5 className="u-align-center-xs u-custom-font u-text u-text-body-alt-color u-text-default u-text-11">
                            special edition ethereum miner pack
                            <br />
                          </h5>
                          <img
                            className="u-border-2 u-border-grey-75 u-image u-image-circle u-preserve-proportions u-image-3"
                            src="assets/images/SKLMiner.png"
                            alt=""
                            data-image-width="1536"
                            data-image-height="1536"
                          />
                          <span className="u-border-7 u-border-palette-1-base u-custom-color-1 u-file-icon u-icon u-icon-circle u-icon-2">
                            <img
                              src="assets/images/1777889-6a83f922.png"
                              alt=""
                            />
                          </span>
                          <h6 className="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-custom-font u-text u-text-default u-text-palette-4-light-2 u-text-12">
                            includes
                            <br />
                            -one johnny newcome <br />
                            -one pickaxe
                            <br />
                            -one windmill
                            <br />
                            -one s.e. ethereum miner
                          </h6>
                          <a
                            onClick={this.mintETHMinerPack}
                            className="u-border-none u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-4-base u-radius-50 u-btn-3"
                          >
                            mint one
                          </a>
                          <h4 className="u-custom-font u-text u-text-body-alt-color u-text-default u-text-13">
                            0.08 eth
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="u-size-30">
                    <div className="u-layout-row">
                      <div className="u-container-style u-layout-cell u-palette-1-dark-2 u-radius-12 u-shape-round u-size-30 u-layout-cell-5">
                        <div className="u-container-layout u-container-layout-5">
                          <img
                            className="u-border-2 u-border-grey-75 u-image u-image-circle u-preserve-proportions u-image-4"
                            src="assets/images/SKLMiner.png"
                            alt=""
                            data-image-width="1536"
                            data-image-height="1536"
                          />
                          <img
                            className="u-border-6 u-border-palette-1-base u-image u-image-circle u-preserve-proportions u-image-5"
                            src="assets/images/skl-square.jpg"
                            alt=""
                            data-image-width="225"
                            data-image-height="225"
                          />
                          <h5 className="u-align-center-xs u-custom-font u-text u-text-default u-text-14">
                            special edition skale miner pack
                          </h5>
                          <h6 className="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-custom-font u-text u-text-default u-text-palette-4-light-1 u-text-15">
                            includes
                            <br />
                            -one johnny newcome
                            <br />
                            -one pickaxe
                            <br />
                            -one windmill
                            <br />
                            -one s.e. skale miner
                          </h6>
                          <a
                            onClick={this.mintSKLMinerPack}
                            className="u-border-none u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-4-base u-radius-50 u-btn-4"
                          >
                            mint one
                          </a>
                          <h4 className="u-custom-font u-text u-text-default u-text-16">
                            0.08 eth
                          </h4>
                        </div>
                      </div>
                      <div className="u-container-style u-grey-90 u-layout-cell u-radius-12 u-shape-round u-size-30 u-layout-cell-6">
                        <div className="u-container-layout u-container-layout-6">
                          <img
                            className="u-border-2 u-border-grey-75 u-image u-image-circle u-preserve-proportions u-image-6"
                            src="assets/images/SKLMiner.png"
                            alt=""
                            data-image-width="1536"
                            data-image-height="1536"
                          />
                          <span class="u-black u-border-6 u-border-palette-1-base u-file-icon u-icon u-icon-circle u-icon-3">
                            <img
                              src="assets/images/1406077-a1dedb0c.png"
                              alt=""
                            />
                          </span>
                          <h5 className="u-custom-font u-text u-text-default u-text-17">
                            ONE ETH BULK SPECIAL
                          </h5>
                          <h6 className="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xs u-custom-font u-text u-text-palette-4-light-1 u-text-18">
                            includes
                            <br />
                            -johnny, PICKAXE, UTILITY WAGON, DATA CENTRE
                            <br />
                            -4 WINDMILLS
                            <br />
                            -5 S.E. BITCOIN MINERS
                            <br />
                            -5 S.E. ETHEREUM MINERS
                            <br />
                            -5 S.E. SKALE MINERS
                          </h6>
                          <a
                            onClick={this.mintOneEthPack}
                            className="u-border-none u-btn u-btn-round u-button-style u-hover-palette-1-light-1 u-palette-4-base u-radius-50 u-btn-5"
                          >
                            mint one
                          </a>
                          <h4 className="u-custom-font u-text u-text-default u-text-19">
                            one eth
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="u-black u-clearfix u-section-3" id="sec-0f51">
          <div className="u-clearfix u-sheet u-sheet-1">
            <h3 className="u-align-center u-custom-font u-text u-text-palette-4-base u-text-1">
              every item BELOW is craftable in game with resources &amp;
              prospect token
            </h3>
            <div className="u-list u-list-1">
              <div className="u-repeater u-repeater-1">
                <div className="u-container-style u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-1">
                    <h4 className="u-align-center u-custom-font u-custom-item u-text u-text-default u-text-2">
                      johnny newcome - enjoys hitting stones, chopping wood and
                      building
                    </h4>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-2">
                    <h4 className="u-align-center u-custom-font u-custom-item u-text u-text-default u-text-3">
                      pickaxes - your most valuable tool, every prospector needs
                      one.
                    </h4>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-3">
                    <h4 className="u-align-center u-custom-font u-custom-item u-text u-text-default u-text-4">
                      windmills provide easy and efficient power to your mining
                      farm
                    </h4>
                  </div>
                </div>
                <div className="u-container-style u-list-item u-repeater-item">
                  <div className="u-container-layout u-similar-container u-container-layout-4">
                    <h4 className="u-align-center u-custom-font u-custom-item u-text u-text-default u-text-5">
                    standout with one of only 100 limited edition pepe prospectors
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="u-clearfix u-expanded-width u-gutter-6 u-layout-wrap u-layout-wrap-1">
              <div className="u-layout">
                <div className="u-layout-row">
                  <div
                    className="u-container-align-center u-container-style u-image u-layout-cell u-radius-12 u-size-15 u-size-30-md u-image-1"
                    data-image-width="1080"
                    data-image-height="1080"
                  >
                    <div className="u-border-2 u-border-palette-3-base u-container-layout u-container-layout-5"></div>
                  </div>
                  <div className="u-container-style u-gradient u-layout-cell u-radius-12 u-size-15 u-size-30-md u-layout-cell-2">
                    <div className="u-border-2 u-border-palette-4-base u-container-layout u-valign-top u-container-layout-6">
                      <div
                        data-interval="5000"
                        data-u-ride="carousel"
                        className="u-carousel u-slider u-slider-1"
                        id="carousel-bac7"
                      >
                        <ol className="u-absolute-hcenter u-carousel-indicators u-carousel-indicators-1">
                          <li
                            data-u-target="#carousel-bac7"
                            className="u-active u-active-grey-10 u-grey-30 u-shape-rectangle"
                            data-u-slide-to="0"
                            style={{ width: "30px", height: "3px" }}
                          ></li>
                          <li
                            data-u-target="#carousel-bac7"
                            className="u-active-grey-10 u-grey-30 u-shape-rectangle"
                            data-u-slide-to="1"
                            style={{ width: "30px", height: "3px" }}
                          ></li>
                          <li
                            data-u-target="#carousel-bac7"
                            className="u-active-grey-10 u-grey-30"
                            data-u-slide-to="2"
                          ></li>
                          <li
                            data-u-target="#carousel-bac7"
                            className="u-active-grey-10 u-grey-30"
                            data-u-slide-to="3"
                          ></li>
                          <li
                            data-u-target="#carousel-bac7"
                            className="u-active-grey-10 u-grey-30"
                            data-u-slide-to="4"
                          ></li>
                        </ol>

                        <div className="u-carousel-inner" role="listbox">
                          <div
                            className="u-active u-carousel-item u-container-align-center u-container-style u-image u-slide u-image-2"
                            data-image-width="3840"
                            data-image-height="2160"
                          >
                            <div className="u-container-layout u-container-layout-7"></div>
                          </div>
                          <div
                            className="u-carousel-item u-container-align-center u-container-style u-image u-slide u-image-3"
                            data-image-width="3840"
                            data-image-height="2160"
                          >
                            <div className="u-container-layout u-container-layout-8"></div>
                          </div>
                          <div
                            className="u-carousel-item u-container-align-center u-container-style u-image u-slide u-image-4"
                            data-image-width="3840"
                            data-image-height="2160"
                          >
                            <div className="u-container-layout u-container-layout-9"></div>
                          </div>
                          <div
                            className="u-carousel-item u-container-align-center u-container-style u-image u-slide u-image-5"
                            data-image-width="3840"
                            data-image-height="2160"
                          >
                            <div className="u-container-layout u-container-layout-10"></div>
                          </div>
                          <div
                            class="u-carousel-item u-container-align-center u-container-style u-image u-slide u-image-6"
                            data-image-width="3840"
                            data-image-height="2160"
                          >
                            <div className="u-container-layout u-container-layout-11"></div>
                          </div>
                        </div>
                        <a
                          className="u-absolute-vcenter u-carousel-control u-carousel-control-prev u-hidden u-spacing-5 u-text-grey-30 u-carousel-control-1"
                          href="#carousel-bac7"
                          role="button"
                          data-u-slide="prev"
                        >
                          <span aria-hidden="true">
                            <svg viewBox="0 0 129 129">
                              <path d="m64.5,122.6c32,0 58.1-26 58.1-58.1s-26-58-58.1-58-58,26-58,58 26,58.1 58,58.1zm0-108c27.5,5.32907e-15 49.9,22.4 49.9,49.9s-22.4,49.9-49.9,49.9-49.9-22.4-49.9-49.9 22.4-49.9 49.9-49.9z"></path>
                              <path d="m70,93.5c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2 1.6-1.6 1.6-4.2 0-5.8l-23.5-23.5 23.5-23.5c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-26.4,26.4c-0.8,0.8-1.2,1.8-1.2,2.9s0.4,2.1 1.2,2.9l26.4,26.4z"></path>
                            </svg>
                          </span>
                          <span className="sr-only">
                            <svg viewBox="0 0 129 129">
                              <path d="m64.5,122.6c32,0 58.1-26 58.1-58.1s-26-58-58.1-58-58,26-58,58 26,58.1 58,58.1zm0-108c27.5,5.32907e-15 49.9,22.4 49.9,49.9s-22.4,49.9-49.9,49.9-49.9-22.4-49.9-49.9 22.4-49.9 49.9-49.9z"></path>
                              <path d="m70,93.5c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2 1.6-1.6 1.6-4.2 0-5.8l-23.5-23.5 23.5-23.5c1.6-1.6 1.6-4.2 0-5.8s-4.2-1.6-5.8,0l-26.4,26.4c-0.8,0.8-1.2,1.8-1.2,2.9s0.4,2.1 1.2,2.9l26.4,26.4z"></path>
                            </svg>
                          </span>
                        </a>
                        <a
                          className="u-absolute-vcenter u-carousel-control u-carousel-control-next u-hidden u-spacing-5 u-text-grey-30 u-carousel-control-2"
                          href="#carousel-bac7"
                          role="button"
                          data-u-slide="next"
                        >
                          <span aria-hidden="true">
                            <svg viewBox="0 0 129 129">
                              <path d="M64.5,122.6c32,0,58.1-26,58.1-58.1S96.5,6.4,64.5,6.4S6.4,32.5,6.4,64.5S32.5,122.6,64.5,122.6z M64.5,14.6    c27.5,0,49.9,22.4,49.9,49.9S92,114.4,64.5,114.4S14.6,92,14.6,64.5S37,14.6,64.5,14.6z"></path>
                              <path d="m51.1,93.5c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l26.4-26.4c0.8-0.8 1.2-1.8 1.2-2.9 0-1.1-0.4-2.1-1.2-2.9l-26.4-26.4c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l23.5,23.5-23.5,23.5c-1.6,1.6-1.6,4.2 0,5.8z"></path>
                            </svg>
                          </span>
                          <span className="sr-only">
                            <svg viewBox="0 0 129 129">
                              <path d="M64.5,122.6c32,0,58.1-26,58.1-58.1S96.5,6.4,64.5,6.4S6.4,32.5,6.4,64.5S32.5,122.6,64.5,122.6z M64.5,14.6    c27.5,0,49.9,22.4,49.9,49.9S92,114.4,64.5,114.4S14.6,92,14.6,64.5S37,14.6,64.5,14.6z"></path>
                              <path d="m51.1,93.5c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l26.4-26.4c0.8-0.8 1.2-1.8 1.2-2.9 0-1.1-0.4-2.1-1.2-2.9l-26.4-26.4c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l23.5,23.5-23.5,23.5c-1.6,1.6-1.6,4.2 0,5.8z"></path>
                            </svg>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className="u-container-align-center u-container-style u-image u-layout-cell u-radius-12 u-shape-round u-size-15 u-size-30-md u-image-7"
                    data-image-width="616"
                    data-image-height="616"
                  >
                    <div className="u-border-2 u-border-palette-3-base u-container-layout u-container-layout-12"></div>
                  </div>
                  <div
                    className="u-container-style u-image u-layout-cell u-radius-12 u-shape-round u-size-15 u-size-30-md u-image-8"
                    data-image-width="500"
                    data-image-height="500"
                  >
                    <div className="u-border-2 u-border-palette-4-base u-container-layout u-container-layout-13"></div>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="u-align-center u-custom-font u-text u-text-6">
              earn $pepe with pepe
            </h4>
            <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-2">
              <div className="u-layout">
                <div className="u-layout-row">
                  <div className="u-container-style u-layout-cell u-size-15 u-size-30-md u-layout-cell-5">
                    <div className="u-container-layout u-valign-top u-container-layout-14">
                      <a
                        onClick={this.mintJohnny}
                        className="u-align-center u-border-none u-btn u-btn-round u-button-style u-grey-10 u-hover-palette-1-light-1 u-radius-50 u-btn-1"
                      >
                        mint
                      </a>
                    </div>
                  </div>
                  <div className="u-container-style u-layout-cell u-size-15 u-size-30-md u-layout-cell-6">
                    <div className="u-container-layout u-container-layout-15">
                      <a
                        onClick={this.mintPickaxe}
                        className="u-align-center u-border-none u-btn u-btn-round u-button-style u-grey-10 u-hover-palette-1-light-1 u-radius-50 u-btn-2"
                      >
                        mint
                      </a>
                    </div>
                  </div>
                  <div className="u-container-style u-layout-cell u-size-15 u-size-30-md u-layout-cell-7">
                    <div className="u-container-layout u-container-layout-16">
                      <a
                        onClick={this.mintWindmill}
                        className="u-align-center u-border-none u-btn u-btn-round u-button-style u-grey-10 u-hover-palette-1-light-1 u-radius-50 u-btn-3"
                      >
                        mint
                      </a>
                    </div>
                  </div>
                  <div className="u-container-style u-layout-cell u-size-15 u-size-30-md u-layout-cell-8">
                    <div className="u-container-layout u-valign-top u-container-layout-17">
                      <a
                        onClick={this.mintPepe}
                        className="u-align-center u-border-none u-btn u-btn-round u-button-style u-grey-10 u-hover-palette-1-light-1 u-radius-50 u-btn-4"
                      >
                        mint
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="u-clearfix u-expanded-width u-layout-wrap u-layout-wrap-3">
              <div className="u-layout">
                <div className="u-layout-row">
                  <div className="u-align-center u-container-style u-layout-cell u-shape-rectangle u-size-15 u-size-30-md u-layout-cell-9">
                    <div className="u-container-layout u-container-layout-18">
                      <h6 className="u-align-center u-custom-font u-text u-text-default u-text-palette-4-base u-text-7">
                        0.05 eth
                      </h6>
                    </div>
                  </div>
                  <div className="u-container-style u-layout-cell u-size-15 u-size-30-md u-layout-cell-10">
                    <div className="u-container-layout u-container-layout-19">
                      <h6 className="u-align-center u-custom-font u-text u-text-default u-text-palette-4-base u-text-8">
                        free
                      </h6>
                    </div>
                  </div>
                  <div className="u-container-style u-layout-cell u-size-15 u-size-30-md u-layout-cell-11">
                    <div className="u-container-layout u-container-layout-20">
                      <h6 className="u-align-center u-custom-font u-text u-text-default u-text-palette-4-base u-text-9">
                        0.03 eth
                      </h6>
                    </div>
                  </div>
                  <div className="u-container-style u-layout-cell u-size-15 u-size-30-md u-layout-cell-12">
                    <div className="u-container-layout u-container-layout-21">
                      <h6 className="u-align-center u-custom-font u-text u-text-default u-text-palette-4-base u-text-10">
                        0.25 eth
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="u-align-center u-custom-font u-text u-text-11">
              3x pros with johnny
              <br />
            </h4>
            <h4 className="u-align-center u-custom-font u-text u-text-12">
              do it yourself 200w windmill
            </h4>
            <h4 className="u-align-center u-custom-font u-text u-text-13">
              5 genesis pickaxe types
              <br />
            </h4>
          </div>
        </section>

        <footer
          className="u-align-center u-black u-clearfix u-footer u-footer"
          id="sec-dbc8"
        >
          <div className="u-clearfix u-sheet u-sheet-1">
            <div className="u-expanded-width u-list u-list-1">
              <div className="u-repeater u-repeater-1">
                <div className="u-border-2 u-border-white u-container-style u-list-item u-radius-12 u-repeater-item u-shape-round">
                  <div className="u-container-layout u-similar-container u-container-layout-1">
                    <img
                      className="u-image u-image-contain u-image-1"
                      src="assets/images/Nebula_Logo.png"
                      alt=""
                      data-image-width="500"
                      data-image-height="500"
                      data-href="https://nebulachain.io"
                      data-target="_blank"
                      rel="noreferrer"
                    />
                  </div>
                </div>
                <div className="u-border-2 u-border-white u-container-style u-list-item u-radius-12 u-repeater-item u-shape-round">
                  <div className="u-container-layout u-similar-container u-container-layout-2">
                    <img
                      className="u-image u-image-contain u-image-2"
                      src="assets/images/skale_logo_white_transparent.svg"
                      alt=""
                      data-image-width="250"
                      data-image-height="150"
                      data-href="https://skale.space"
                      data-target="_blank"
                      rel="noreferrer"
                    />
                  </div>
                </div>
              </div>
            </div>
            <h4 className="u-custom-font u-text u-text-default u-text-palette-4-base u-text-1">
              verse
            </h4>
            <h6 className="u-custom-font u-text u-text-default u-text-palette-3-base u-text-2">
              prospectorsnft
            </h6>
            <h4 className="u-custom-font u-text u-text-default u-text-palette-1-base u-text-3">
              voxel
            </h4>
            <h6 className="u-align-center u-custom-font u-text u-text-default u-text-4">
              <a
                href="https://pickaxecrypto.io"
                className="u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Copyright © 2023 pickaxe crypto
              </a>
            </h6>
            <span
              className="u-file-icon u-icon u-text-palette-1-base u-icon-1"
              data-href="https://discord.gg/jx5qSyjV9e"
              data-target="_blank"
            >
              <img src="assets/images/3670325-bb91a2e5.png" alt="" />
            </span>
          </div>
        </footer>
      </div>
    );
  }
}
export default Home;
